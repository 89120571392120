@import "../../../styles/vars.scss";

.footer {
  padding: 0 40px 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 72px;

  @media screen and (max-width: $tablet) {
    position: static;
    margin-top: 60px;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    align-items: center;
    padding: 0 20px 40px;
    gap: 20px;
  }

  .copyright {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #76788b;
    @media screen and (max-width: $mobile) {
      line-height: 20px;
    }
  }

  .contactsWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;

    @media screen and (max-width: $mobile) {
      flex-direction: column;
      gap: 20px;
    }

    .contacts {
      display: flex;
      align-items: flex-start;

      @media screen and (max-width: $mobile) {
        flex-direction: column;
      }
      .contactInfo {
        text-decoration: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        color: #76788b;

        @media screen and (max-width: $mobile) {
          line-height: 20px;
        }

        &:hover {
          color: #fff;
        }

        &:nth-child(1)::after {
          content: "|";
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          text-align: center;
          color: #76788b;
          margin: 0 12px;
          @media screen and (max-width: $mobile) {
            display: none;
          }
        }
      }
    }

    .socialNetwork {
      display: flex;
      align-items: flex-start;
      gap: 4px;

      .socialNetworkItem {
        width: 24px;
        height: 24px;
        @media screen and (max-width: $mobile) {
          width: 32px;
          height: 32px;
        }
        &:hover {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}
