@import "../../styles/vars.scss";

.logo {
  display: flex;
  align-items: center;
  color: #fff;
  .logoHref {
    display: flex;
    align-items: center;
    cursor: pointer;
    .logoTitle {
      margin-left: 8px;
      margin-right: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      text-transform: uppercase;
      @media screen and (max-width: $tablet) {
        display: none;
      }
    }
  }

  .logoSubTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.38);
    @media screen and (max-width: $tablet) {
      display: none;
    }
  }
}
