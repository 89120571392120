@import "./vars.scss";

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background-color: black;
  color: white;

  font-family: "Graphik LCG", sans-serif;
  font-size: 16px;

  /* Hide scrollbar for IE and Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  scroll-behavior: smooth;
  overflow-y: hidden;

  @media screen and (max-width: $tablet) {
    overflow-y: auto;
  }

  &.scroll-down header {
    transform: translate3d(0, -100%, 0);
  }

  &.scroll-up header {
    transform: none;
  }
}

.no-scroll {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}
