@import "../../styles/vars.scss";

.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  width: 100%;
  height: 72px;
  line-height: 0;
  z-index: 10;
  @media screen and (max-width: $tablet) {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
  }
  .navContainer {
    display: flex;
    align-items: center;
    user-select: none;
    gap: 60px;
    color: #ffffff;

    .burgerBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        color: $primary;
        svg {
          fill: $primary;
        }
      }
      .burgerTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .header {
    padding: 20px;
    .navContainer {
      gap: 26px;
    }
    .burgerTitle {
      display: none;
    }
  }
}
