@import "../../../styles/vars.scss";

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 25%;
  gap: 30px;
  @media screen and (max-width: $tablet) {
    flex: 1 1 48%;
  }
  @media screen and (max-width: $mobile) {
    flex: 1 1 100%;
  }
  .title {
    width: max-content;
    padding: 8px 24px;
    border: 1px solid #fff;
    border-radius: 100px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 0px 24px;
    gap: 12px;
    .listItem {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
      @media screen and (max-width: $laptop), screen and (max-height: $height) {
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
}
