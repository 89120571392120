@import "../../../styles/vars.scss";

.chooseItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  width: 314px;
  height: 260px;
  background: linear-gradient(95.04deg, #141418 0%, rgba(33, 33, 37, 0.6) 100%);
  border: 1px solid #323235;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $primary;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 0.2s;
  }
  &:hover {
    ::after {
      opacity: 0.01;
    }
  }
  @media screen and (max-width: $laptop), screen and (max-height: $height) {
    padding: 24px;
    width: 25%;
  }
  @media screen and (max-width: $tablet) {
    padding: 20px;
    width: 100%;
    height: 88px;
    &:hover {
      ::after {
        opacity: 0;
      }
    }
    &:active {
      ::after {
        opacity: 0.01;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    @media screen and (max-width: $tablet) {
      flex-direction: row;
      align-items: center;
      height: 48px;
      gap: 24px;
    }
    .itemLogo {
      width: 72px;
      height: 72px;
      background: #202023;
      border: 1px solid #323235;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $tablet) {
        min-width: 48px;
        width: 48px;
        height: 48px;
      }
      .icon {
        width: 32px;
        height: 32px;

        @media screen and (max-width: $tablet) {
          width: 24px;
          height: 24px;
        }
      }
    }
    .itemTitle {
      height: 64px;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #fff;
      @media screen and (max-width: $laptop), screen and (max-height: $height) {
        height: auto;
        font-size: 20px;
        line-height: 32px;
      }
      @media screen and (max-width: $tablet) {
        height: auto;
        font-size: 20px;
        line-height: 30px;
      }
      @media screen and (max-width: $mobile) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
