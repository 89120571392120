@font-face {
  font-family: "Graphik LCG";
  font-weight: normal;
  font-style: normal;

  src: url("../assets/fonts/GraphikLCG-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik LCG";
  font-weight: bold;
  font-style: normal;

  src: url("../assets/fonts/GraphikLCG-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik LCG";
  font-weight: 300;
  font-style: normal;

  src: url("../assets/fonts/GraphikLCG-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik LCG";
  font-weight: 600;
  font-style: normal;

  src: url("../assets/fonts/GraphikLCG-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik LCG";
  font-weight: 900;
  font-style: normal;

  src: url("../assets/fonts/GraphikLCG-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik LCG";
  font-weight: 500;
  font-style: normal;

  src: url("../assets/fonts/GraphikLCG-Medium.ttf") format("truetype");
}
