@import "../../styles/vars.scss";

.sendForm {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    color: $primary;
  }
}
