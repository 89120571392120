@import "../../styles/vars.scss";

.lifeTime {
  .header {
    display: flex;
    align-items: center;
    @media screen and (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
    }

    h3 {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
      @media screen and (max-width: $laptop), screen and (max-height: $height) {
        font-size: 32px;
        line-height: 40px;
      }
      @media screen and (max-width: $tablet) {
        font-size: 24px;
        line-height: 40px;
      }
    }

    h6 {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #a2a5be;

      @media screen and (max-width: $laptop), screen and (max-height: $height) {
        font-size: 14px;
        line-height: 20px;
      }
      @media screen and (max-width: $mobile) {
        margin-top: 20px;
        br {
          display: none;
        }
      }
    }

    .divider {
      width: 2px;
      height: 40px;
      background-color: rgba(255, 255, 255, 0.2);
      margin: 0 60px;
      @media screen and (max-width: $laptop), screen and (max-height: $height) {
        height: 32px;
      }
      @media screen and (max-width: $tablet) {
        margin: 0 40px;
      }
      @media screen and (max-width: $mobile) {
        display: none;
      }
    }
  }

  .content {
    margin-top: 100px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 360px);
    justify-content: space-between;
    row-gap: 60px;
    @media screen and (max-width: $laptop), screen and (max-height: $height) {
      grid-template-columns: repeat(3, 288px);
      margin-top: 60px;
      row-gap: 48px;
    }
    @media screen and (max-width: $tablet) {
      grid-template-columns: repeat(2, 288px);
    }
    @media screen and (max-width: $mobile) {
      grid-template-columns: repeat(1, 100%);
    }

    .item {
      width: 360px;
      height: 228px;
      @media screen and (max-width: $laptop), screen and (max-height: $height) {
        width: 288px;
        height: 172px;
      }
      @media screen and (max-width: $mobile) {
        width: 100%;
        height: auto;
      }

      .icon {
        height: 72px;
        width: 72px;
        @media screen and (max-width: $laptop), screen and (max-height: $height) {
          height: 60px;
          width: 60px;
        }
        svg:hover {
          * {
            opacity: 1;
            transition: all 0.5s;
          }
        }
      }

      .title {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        margin-top: 20px;
        @media screen and (max-width: $laptop), screen and (max-height: $height) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        margin-top: 12px;
        color: #a2a5be;
        @media screen and (max-width: $laptop), screen and (max-height: $height) {
          font-size: 14px;
          line-height: 20px;
          margin-top: 8px;
        }
      }
    }
  }
}
