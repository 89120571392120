$background: rgb(0, 0, 0);
$primary: #0071ff;
$pageHeight: 100vh;

$mobile: 767px;
$tablet: 1023px;
$laptop: 1439px;
$desktop: 1920px;

$height: 767px;
