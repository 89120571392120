@import "../../../styles/vars.scss";

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #fff;
  margin-bottom: 60px;

  @media screen and (max-width: $laptop), screen and (max-height: $height) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: $tablet) {
    font-size: 24px;
    line-height: 32px;
  }

  @media screen and (max-width: $mobile) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.step {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  height: 398px;

  @media screen and (max-width: $tablet) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
