@import "../../styles/vars.scss";
.team {
  background-color: #0a0a0c;
  cursor: none;

  :global(.carousel.carousel-slider) {
    overflow: visible;
  }
  :global(.carousel.carousel-slider .control-dots) {
    margin-bottom: -56px;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    display: flex;
    margin-top: 65px;
    flex-flow: column wrap;
    flex: 1 1 33%;
    gap: 40px;
    color: $primary;

    h1 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (max-width: $laptop), screen and (max-height: $height) {
      margin-top: 50px;
      font-size: 20px;
      line-height: 28px;
      gap: 20px;
    }
    @media screen and (max-width: $tablet) {
      flex: 1 1 50%;
    }
    @media screen and (max-width: $mobile) {
      margin-top: 0;
      margin-bottom: 40px;
    }
  }
  .description {
    text-align: center;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;

    @media screen and (max-width: $laptop), screen and (max-height: $height) {
      font-size: 12px;
      line-height: 20px;
    }
    @media screen and (max-width: $mobile) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .container {
    display: flex;
    justify-content: center;

    .desktop {
      display: flex;
      flex-wrap: wrap;
      gap: 100px 0;
      @media screen and (max-width: $laptop), screen and (max-height: $height) {
        gap: 30px 0;
      }
      @media screen and (max-width: $tablet) {
        gap: 40px 0;
      }
      @media screen and (max-width: $mobile) {
        gap: 0;
      }
    }
    .mobile {
      display: none;
    }
    @media screen and (max-width: $mobile) {
      .desktop {
        display: none;
      }
      .mobile {
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: 0 20px;
      }
    }
  }
  .cursor {
    pointer-events: none;

    .cursorCircle {
      position: absolute;
      top: 0;
      left: 0;
      mix-blend-mode: difference;
      z-index: 900;

      circle {
        fill: white;
      }
    }
    @media screen and (max-width: $tablet) {
      .cursorCircle {
        display: none;
      }
    }
  }
}
