@import "../../styles/vars.scss";

.loading {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  opacity: 0;
  animation: opacity 2s ease;
  @media screen and (max-width: $mobile) {
    animation: opacity-mobile 2s ease;
  }
}

@keyframes opacity {
  0%    { opacity: 1; }
  100%  { opacity: 0; }
}

@keyframes opacity-mobile {
  0%    { opacity: 1; }
  40%    { opacity: 1; }
  100%  { opacity: 0; }
}
